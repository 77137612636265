import React from 'react';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import Navigation from '@components/Navigation/index';
import FooterImage from '@components/FooterImage/index';
import FooterV2 from '@components/FooterV2/index';

export default function Terms() {
  return (
    <>
      <GatsbySeo
        title="Terms"
        description="Build internship-grade projects in a real developer environment and accelerate your full stack or backend developer career with a job-ready project portfolio. Enroll and start for free."
        noindex={true}
      ></GatsbySeo>
      <Navigation />
      <div className="container pt-32 pb-20">
        <div className="row">
          <div className="col-lg-12 mt-3">
            <div className={`bg-v5-neutral-250 pb-1 pt-2 pl-3 pr-3`}>
              <h5>Terms and Conditions</h5>
            </div>
            <div
              className={`mb-4 border-[1px] border-v5-neutral-250 pb-3 pt-3 pl-3 pr-3 text-sm leading-relaxed`}
            >
              Please read these Terms and Conditions before using&nbsp;
              <a
                href="https://www.crio.do"
                target="_blank"
                className="underline"
                rel="noreferrer"
              >
                https://www.crio.do
              </a>{' '}
              (hereinafter referred to as the Website) operated by{' '}
              <strong>QIFT SOLUTECH PRIVATE LIMITED</strong> (hereinafter
              referred to as Company) with its registered office at #175 and
              #176, Dollars Colony, Phase 4, JP Nagar, Bannerghatta Main Road,
              Bengaluru, Karnataka 560076
              <br></br>
              <br></br>
              YOUR ACCESS TO AND USE OF THE SERVICE IS CONDITIONED ON YOUR
              ACCEPTANCE OF AND COMPLIANCE WITH THESE TERMS. THESE TERMS APPLY
              TO ALL VISITORS, USERS AND OTHERS WHO ACCESS OR USE THE SERVICE.
              BY ACCESSING OR USING THE SERVICE YOU AGREE TO BE BOUND BY THESE
              TERMS. IF YOU DISAGREE WITH ANY PART OF THE TERMS THEN YOU ARE
              REQUESTED TO REFRAIN FROM ACCESSING THE SERVICE.
              <br></br>
              <br></br>
              <strong>DEFINITIONS</strong> <br></br>
              <ol>
                <li>
                  ‘Contract’ – the document or documents setting out the
                  services to be provided by Us together with these terms and
                  conditions
                </li>
                <li>
                  “We”, “Our”, and “Us” - shall mean and refer to the Company
                </li>
                <li>
                  ‘You’, ‘User’ – the person, firm or organisation engaging our
                  services.
                </li>
                <li>
                  ‘Software’ - any executable files, help files and all other
                  documentation and content that is in addition to the service
                  available on the Website for download, installation or
                  purchase by Users.
                </li>
              </ol>
              <br></br>
              <strong>1. FORMATION OF CONTRACT</strong>
              <br></br>
              <br></br>A contract would exist between Us and You as soon as You
              make the initial payment for booking Our services.
              <br></br>
              <br></br>
              <strong>2. SCOPE OF SERVICES</strong>
              <br></br>
              <br></br>
              We provide an environment optimised for learning and empower
              developers with “work-like” Micro- Experiences chosen from
              industry. The developers are given an insight into the real world
              problems and the solution landscape. We offer services like
              Learning Content, a Work-like infrastructure for learning, Git
              account and Code stubs, Provisioned Virtual machines, Test suite
              and assessment service, Slack workspace ,Mentoring over slack or
              in-person and career services
              <br></br>
              <br></br>
              <strong>3. REGISTRATION</strong>
              <ul>
                <li>
                  Users shall register in order to get access to the services
                  provided by the Company through the Website.
                </li>
                <li>
                  Facebook or Google Plus Accounts may be linked in order to
                  facilitate registration.
                </li>
                <li>
                  Users may explore the Website on a trial for a period of 7
                  days through an Access Code provided by Us. Please note that
                  You shall access only such limited content and functions as
                  may be prescribed by Us during the trial period.
                </li>
                <li>
                  Only those individuals who have attained majority can register
                  themselves with Us. Individuals under the age of 18 years
                  shall utilise or browse our Website only with the involvement,
                  guidance and supervision of their parents and / or legal
                  guardians, using such parent /legal guardian’s registered
                  account.
                </li>
              </ul>
              <br></br>
              <strong>4. PAYMENT</strong>
              <ul>
                <li>Registration to the Website is free of cost.</li>
                <li>
                  Customers shall pay the prescribed amount for availing any of
                  the Services offered by Us. We shall provide access to the
                  content only after receiving the full payment.
                </li>
                <li>
                  Payment shall be made through credit/debit card, digital
                  wallets,internet banking or NBFC loans
                </li>
                <li>
                  We use third-party payment gateways and payment partners. The
                  relationship between the Users and third-party payment
                  providers shall be governed by the Terms of Use and Privacy
                  Policy of the said payment provider and We shall not be party
                  to any dispute arising there from. We are also not liable for
                  any errors or delays made by the third-party payment gateway.
                </li>
              </ul>
              <br></br>
              <strong>5. CANCELLATION/REFUND POLICY</strong>
              <ul>
                <li>
                  You cannot change or cancel your subscription plan once You
                  have subscribed and made the requisite payment. Consequently,
                  no refunds shall be processed on cancellation of subscription
                  or membership.
                </li>
                <li>
                  We shall not provide refunds where Your access is interrupted
                  due to Your insufficient system requirements such as poor
                  Internet connection, incompatible hardware/software and the
                  like.
                </li>
              </ul>
              <br></br>
              <strong>6. WARRANTIES AND LIABILITIES</strong>
              <ul>
                <li>
                  We do not warrant, guarantee or endorse the accuracy,
                  completeness or quality of content and representations offered
                  on our Website.
                </li>
                <li>
                  We undertake no liability, in any circumstances whatsoever,
                  for the standard of services provided by third-party payment
                  gateways.
                </li>
                <li>
                  We are not liable for events beyond the company’s control such
                  as natural calamities, acts of terrorism or other similar
                  factors which cannot be reasonably mitigated against.
                </li>
                <li>
                  We are not responsible for any interruption of service or any
                  loss of data while transmitting any information over the
                  internet.
                </li>
                <li>
                  We do not have any control over the content and resources
                  provided by the third party websites through ads or links.
                </li>
              </ul>
              <br></br>
              <strong>
                7. OBLIGATIONS AND FORMAL UNDERTAKINGS AS TO CONDUCT
              </strong>
              <ul>
                <li>
                  You agree and acknowledge that You are a restricted user of
                  this Website, and that You shall refrain from providing any
                  information:
                  <ul>
                    <li>
                      which is false, fraudulent, inaccurate, misleading or
                      incomplete; or
                    </li>
                    <li>
                      which is defamatory, libellous, unlawfully threatening or
                      unlawfully harassing; or
                    </li>
                    <li>
                      which contains any viruses, trojan horses, worms, time
                      bombs, cancelbots, easter eggs or other computer
                      programming routines or executable files that may damage,
                      detrimentally interfere with, surreptitiously intercept or
                      expropriate any system, data or personal information of
                      any person whatsoever; or
                    </li>
                    <li>
                      which creates liability or causes us to lose in whole or
                      in part, the services of our Internet Service Providers or
                      other service providers/suppliers.
                    </li>
                  </ul>
                </li>
                <li>
                  You further undertake that You shall not engage in the
                  following activities:
                  <ul>
                    <li>
                      Systematic retrieval of data or other content from the
                      Website to create or compile, directly or indirectly, a
                      collection, compilation, database, or directory without
                      written permission from us.
                    </li>
                    <li>
                      Any unauthorized use of the Website, including collection
                      of usernames and/or email addresses of other users by
                      electronic or other means for the purpose of sending
                      unsolicited email, or creating user accounts by automated
                      means or under false pretences.
                    </li>
                    <li>
                      Circumvention, disablement, or interference with
                      security-related features of the Website, including
                      features that prevent or restrict the use or copying of
                      any content or enforce limitations on the use of the
                      Website.
                    </li>
                    <li>Unauthorized framing of or linking to the Website.</li>
                    <li>
                      Trick, defraud, or mislead Us and other Users, especially
                      in any attempt to learn sensitive account information such
                      as user passwords.
                    </li>
                    <li>
                      Attempt to impersonate another user or person or use the
                      username of another user.
                    </li>
                    <li>Sell or otherwise transfer Your profile.</li>
                    <li>
                      Use any information obtained from the Website in order to
                      harass, abuse, or harm another person.
                    </li>
                    <li>
                      Use the Website as part of any effort to compete with us
                      or for any revenue-generating endeavour or commercial
                      enterprise.
                    </li>
                    <li>
                      Disparage, tarnish, or otherwise harm, in our opinion, Us
                      and/or the Website.
                    </li>
                    <li>
                      Use the Website in a manner inconsistent with any
                      applicable laws or regulations.
                    </li>
                    <li>
                      Follow the user guide and policy prescribed and updated
                      time to time by the Company{' '}
                    </li>
                  </ul>
                </li>
              </ul>
              <br></br>
              <strong>8. TERMINATION OF CONTRACT</strong>
              <ul>
                <li>
                  You may discontinue from our services at any point of time, by
                  either opting out of your membership from the Website or by
                  writing to us at&nbsp;
                  <a
                    href="mailto:ping@criodo.com"
                    className="underline"
                    target="_blank"
                    rel="noreferrer"
                  >
                    ping@criodo.com
                  </a>
                  .
                </li>
                <li>
                  We reserve the right to unilaterally terminate your use of the
                  Website without notice or any liability for reasons of breach
                  of the terms mentioned herein.
                </li>
                <li>
                  We also reserve the right to deny access to particular Users,
                  to all/any of the services without any prior notice or
                  explanation in order to protect the interests of other
                  visitors to the Website.
                </li>
                <li>
                  You shall continue to be bound by these Terms, and shall have
                  no right to terminate these Terms till the expiry of the same.
                </li>
              </ul>
              <br></br>
              <strong>9. INDEMNITY</strong>
              <br></br>
              <br></br>
              You agree to indemnify, defend and hold Us harmless from and
              against all losses, liabilities, claims, damages, demands, costs
              and expenses (including legal fees and disbursements in connection
              therewith and interest chargeable thereon) asserted against or
              incurred by Us that arise out of or result from, any breach or
              non-performance of any representation, warranty, covenant or
              agreement or obligation to be performed by You pursuant to these
              terms of use. Further, You agree to hold us harmless against any
              claims made by any third party due to, or arising out of, or in
              connection with:
              <ul>
                <li>Your use of the Platform,</li>
                <li>Your violation of these Terms and Conditions;</li>
                <li>Your violation of any rights of another;</li>
                <li>
                  Your alleged improper conduct pursuant to these Services;
                </li>
                <li>Your conduct in connection with the Website;</li>
              </ul>
              You agree to fully co-operate in indemnifying us at your expense.
              You also agree not to reach a settlement with any party without
              our consent.
              <br></br>
              <br></br>
              In no event shall We be liable to compensate You or any third
              party for any special, incidental, indirect, consequential or
              punitive damages whatsoever, including those resulting from loss
              of use, data or profits, whether or not foreseeable, and whether
              or not We had been advised of the possibility of such damages, or
              based on any theory of liability, including breach of contract or
              warranty, negligence or other tortuous action, or any other claim
              arising out of or in connection with the Your use of or access to
              the Website and/or the Services or materials contained therein.
              <br></br>
              <br></br>
              <strong>10. INTELLECTUAL PROPERTY RIGHTS</strong>
              <br></br>
              <br></br>
              Nothing contained herein shall give the Customer a right to use
              any of the Website’s trade names, trademarks, service marks,
              logos, domain names, information, questions, answers, solutions,
              reports and other distinctive features, save according to the
              provisions of these Terms. All logos, content, trademarks, brand
              names, service marks, domain names are the property of the Company
              or the respective copyright or trademark owner. Furthermore, with
              respect to the application created by the Company, the Company
              shall be the exclusive owner of all the designs, graphics and the
              like, related to the Website.
              <br></br>
              <br></br>
              The Website contains copyrighted material, trademarks, trade
              secrets, patents, and other proprietary information, including,
              but not limited to, text, software, photos, video, graphics,
              music, sound, and the entire contents of the Company protected by
              copyright as a collective work under the applicable copyright
              laws.
              <br></br>
              <br></br>
              You shall not decipher, decompile, disassemble, or reverse
              engineer any of the software comprising or in any way making up a
              part of the Website. No user is authorised to copy or adapt the
              Website’s software, including but not limited to Flash, PHP, HTML,
              JavaScript, or other code.
              <br></br>
              <br></br>
              We reserve the right to remove any content from our Website that
              is alleged to infringe any other person’s intellectual property.
              <br></br>
              <br></br>
              <strong>11. DISPUTE RESOLUTION TERMS</strong>
              <br></br>
              <br></br>
              It is expressly agreed to by the parties hereto that the
              formation, interpretation and performance of these Terms and any
              disputes arising therefrom will be resolved through a two-step
              Alternate Dispute Resolution mechanism. It is further agreed to by
              the parties that the contents of this Section shall survive even
              after the termination or expiry of the Terms and/or the Privacy
              Policy.
              <br></br>
              <br></br>
              In case of any dispute between the parties, the parties will
              attempt to resolve the same amicably amongst themselves, to the
              mutual satisfaction of all parties. In the event that the Parties
              are unable to reach such an amicable solution within thirty (30)
              days of one party communicating the existence of a dispute to the
              other, the dispute shall be resolved by arbitration.
              <br></br>
              <br></br>
              The parties expressly agree that the Terms, Policy and any other
              agreements entered into between the parties are governed by the
              laws, rules and regulations of India. The policy shall be governed
              by and construed in accordance with the laws of India. Each party
              hereby irrevocably submits to the exclusive jurisdiction of the
              courts of Bangalore, India, for the adjudication of any dispute
              hereunder or in connection herewith.
              <br></br>
              <br></br>
              <strong>12. NOTICES</strong>
              <br></br>
              <br></br>
              Any and all communication relating to any dispute or grievance
              experienced by the User may be communicated to the Company by the
              Customer by emailing to&nbsp;
              <a
                href="mailto:ping@criodo.com"
                className="underline"
                target="_blank"
                rel="noreferrer"
              >
                ping@criodo.com
              </a>
              <br></br>
              <br></br>
              <strong>13. MISCELLANEOUS PROVISIONS</strong>
              <ul>
                <li>
                  <strong>Entire Agreement: </strong>These Terms, read with the
                  Privacy Policy, form the complete and final contract between
                  the User and the Company with respect to the subject matter
                  hereof and supersedes all other communications,
                  representations and agreements (whether oral, written or
                  otherwise) relating thereto.
                </li>
                <li>
                  <strong>Waiver: </strong>The failure of either You or Us, at
                  any time to require performance of any provision of these
                  Terms, shall in no manner affect such party's right at a later
                  time to enforce the same. No waiver by either party of any
                  breach of these Terms, whether by conduct or otherwise, in any
                  one or more instances, shall be deemed to be or construed as a
                  further or continuing waiver of any such breach, or a waiver
                  of any other breach of these Terms.
                </li>
                <li>
                  <strong>Severability: </strong>If any provision of these Terms
                  is held to be illegal, invalid, or unenforceable by any court
                  or authority of competent jurisdiction, the legality,
                  validity, and enforceability of the remaining provisions of
                  these Terms shall in no way be affected or impaired thereby,
                  and each such provision shall be valid and enforceable to the
                  fullest extent permitted by law. In such case, these Terms
                  shall be reformed to the minimum extent necessary to correct
                  any illegality, invalidity or unenforceability.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <FooterImage></FooterImage>
      <FooterV2 />
    </>
  );
}
